.tabs {
  max-width: 600px;
  margin: auto;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.tabs * {
  box-sizing: border-box;
}

.tabs__bar-wrap {
  position: relative;
}

.tabs__bar-wrap.has-left-overflow::before {
  content: '';
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  width: 30px;
  pointer-events: none;
}

.tabs__bar-wrap.has-right-overflow::after {
  content: '';
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  width: 30px;
  pointer-events: none;
}

.tabs__bar-wrap.has-left-overflow::before {
  left: 0;
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.95) 100%);
}

.tabs__bar-wrap.has-right-overflow::after {
  right: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.95) 100%);
}

.tabs__bar {
  position: relative;
  display: flex;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 3px 3px 0 0;
  box-shadow: inset 0 -5px 5px -3px rgba(0, 0, 0, 0.05);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 480px) {
  .tabs__bar {
    min-width: auto;
    overflow: hidden;
  }
}

.tabs__line {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background-color: #4285f4;
  will-change: transform;
  transform-origin: left;
}

.tabs__controls {
  flex: 1 0 auto;
  min-width: 150px;
  display: block;
  padding: 20px 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1px;
}

.tabs__controls.is-active {
  color: #4285f4;
}

@media (min-width: 480px) {
  .tabs__controls {
    min-width: auto;
  }
}

.tabs__controls:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.tabs__controls:active {
  -webkit-user-select: none;
          user-select: none;
}

.tabs__content {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: calc(600px * 3);
  overflow-y: hidden;
  background-color: #fff;
  will-change: transform, height;
}

.tabs__content.has-grab {
  cursor: grabbing;
}

.tabs__section {
  padding: 30px;
  width: 600px;
}

